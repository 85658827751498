import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TopHeader from './components/TopHeader';
import Header from './components/Header';
import Slider from './components/Slider';
import Content from './components/Content';
import Footer from './components/Footer';
import Login from './components/Login';
import './App.css';

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  return user ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  return user ? <Navigate to="/" /> : children;
};

const App = () => {
  const [activePage, setActivePage] = useState('home');

  return (
    <Router>
      <div className="app">
        <TopHeader />
        <Header setActivePage={setActivePage} />
        <Slider />
        <Routes>
          <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/" element={<PrivateRoute><Content activePage={activePage} /></PrivateRoute>} />
          {/* Añadir más rutas privadas según sea necesario */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
