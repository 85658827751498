import React, { useState } from 'react';
import {
  TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Pagination, MenuItem, Select, FormControl, InputLabel, Dialog, DialogTitle,
  DialogContent, DialogActions, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '20px',
    maxWidth: '90%',
    margin: '0 auto',
  },
  table: {
    minWidth: 650,
  },
  headerCell: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#FFFFFF !important',
    fontSize: '0.875rem !important',
    fontWeight: 'bold !important', // Texto en negrilla
  },
  bodyCell: {
    fontSize: '0.875rem',
  },
  stripedRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  pagination: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  instructions: {
    textAlign: 'center',
    marginTop: '20px',
    fontStyle: 'italic',
  }
}));

const cities = [
  { code: '', name: 'Buscar en todas' },
  { code: '001', name: 'BARRANQUILLA' },
  { code: '078', name: 'BARANOA' },
  { code: '137', name: 'CAMPO DE LA CRUZ' },
  { code: '141', name: 'CANDELARIA' },
  { code: '296', name: 'GALAPA' },
  { code: '372', name: 'JUAN DE ACOSTA' },
  { code: '421', name: 'LURUACO' },
  { code: '433', name: 'MALAMBO' },
  { code: '436', name: 'MANATÍ' },
  { code: '520', name: 'PALMAR DE VARELA' },
  { code: '549', name: 'PIOJÓ' },
  { code: '558', name: 'POLONUEVO' },
  { code: '560', name: 'PONEDERA' },
  { code: '573', name: 'PUERTO COLOMBIA' },
  { code: '606', name: 'REPELÓN' },
  { code: '634', name: 'SABANAGRANDE' },
  { code: '638', name: 'SABANALARGA' },
  { code: '675', name: 'SANTA LUCÍA' },
  { code: '685', name: 'SANTO TOMÁS' },
  { code: '758', name: 'SOLEDAD' },
  { code: '770', name: 'SUAN' },
  { code: '832', name: 'TUBARÁ' },
  { code: '849', name: 'USIACURÍ' },
];

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const Content = ({ activePage }) => {
  const classes = useStyles();
  const [selectedCity, setSelectedCity] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [mapCenter, setMapCenter] = useState({ lat: 10.96854, lng: -74.78132 }); // Coordenadas iniciales de Barranquilla
  const [mapAddress, setMapAddress] = useState('');
  const itemsPerPage = 10;

  const fetchData = async (city, query, page) => {
    try {
      Swal.fire({
        title: 'Cargando...',
        text: 'Por favor, espere',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const response = await fetch(`https://macroproyectoscartagenadeindias.com/atlantico/search.php?city=${city}&query=${query}&page=${page}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Network response was not ok');
      }
      const result = await response.json();
      if (result.data && Array.isArray(result.data)) {
        setData(result.data);
      } else {
        setData([]);
      }
      setTotalItems(result.totalItems || 0);
      setCurrentPage(result.currentPage || 1);

      Swal.close();
    } catch (error) {
      Swal.close();
      console.error('Fetch error:', error);
      setData([]);
      setTotalItems(0);
      Swal.fire('Error', 'Hubo un problema al obtener los datos.', 'error');
    }
  };

  const handleSearch = async () => {
    fetchData(selectedCity, searchQuery, 1);
  };

  const handlePageChange = (event, value) => {
    fetchData(selectedCity, searchQuery, value);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value);
  };

  const handleOpenMap = async (address) => {
    const fullAddress = `${address}, ${cities.find(city => city.code === selectedCity).name}, Atlántico`;
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=AIzaSyADkILxXgusP6VfyKExcU5Z7tVivY1e4RE`);
      const data = await response.json();
      if (data.status === 'OK') {
        const location = data.results[0].geometry.location;
        setMapCenter(location);
        setMapAddress(fullAddress);
        setOpenModal(true);
      } else {
        Swal.fire('Error', 'No se pudo obtener la ubicación del mapa.', 'error');
      }
    } catch (error) {
      console.error('Geocoding error:', error);
      Swal.fire('Error', 'Hubo un problema al obtener la ubicación del mapa.', 'error');
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.container}>
      <h2>Home Page</h2>
      <div style={{ marginBottom: '20px' }}>
        <FormControl variant="outlined" style={{ marginRight: '10px', minWidth: 200 }}>
          <InputLabel id="city-select-label">Ciudad</InputLabel>
          <Select
            labelId="city-select-label"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
            label="Ciudad"
          >
            {cities.map((city) => (
              <MenuItem key={city.code} value={city.code}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Consulta"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Buscar
        </Button>
      </div>
      {data.length > 0 ? (
        <>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.headerCell}>Dpto</TableCell>
                  <TableCell className={classes.headerCell}>Mun</TableCell>
                  <TableCell className={classes.headerCell}>Número predial</TableCell>
                  <TableCell className={classes.headerCell}>Tr</TableCell>
                  <TableCell className={classes.headerCell}>Nor</TableCell>
                  <TableCell className={classes.headerCell}>Treg</TableCell>
                  <TableCell className={classes.headerCell}>Nombres y apellidos</TableCell>
                  <TableCell className={classes.headerCell}>Td</TableCell>
                  <TableCell className={classes.headerCell}>Documento</TableCell>
                  <TableCell className={classes.headerCell}>Dirección</TableCell>
                  <TableCell className={classes.headerCell}>De</TableCell>
                  <TableCell className={classes.headerCell}>A-terreno</TableCell>
                  <TableCell className={classes.headerCell}>A-cons</TableCell>
                  <TableCell className={classes.headerCell}>Avalúo</TableCell>
                  <TableCell className={classes.headerCell}>Vigencia</TableCell>
                  <TableCell className={classes.headerCell}>Num-pre-ant</TableCell>
                  <TableCell className={classes.headerCell}>Mapa</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index} className={classes.stripedRow}>
                    <TableCell className={classes.bodyCell}>{item.dpto}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.mun}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.numero_predial_de____pos}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.tr}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.nor}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.treg}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.nombres_y_apellidos}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.td}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.numero_doc}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.direccion_del_predio}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.de}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.a_terreno}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.a_cons}</TableCell>
                    <TableCell className={classes.bodyCell}>{formatCurrency(item.avaluo)}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.vigencia}</TableCell>
                    <TableCell className={classes.bodyCell}>{item.num_pre_ant}</TableCell>
                    <TableCell className={classes.bodyCell}>
                      <Button variant="outlined" color="primary" onClick={() => handleOpenMap(item.direccion_del_predio)}>
                        Ver Mapa
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(totalItems / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            className={classes.pagination}
          />
        </>
      ) : (
        <Typography variant="body1" className={classes.instructions}>
          Selecciona una ciudad y escribe el número de documento o nombre de la persona para buscarla.
        </Typography>
      )}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>Ubicación aproximada del predio: {mapAddress}</DialogTitle>
        <DialogContent>
          <LoadScript googleMapsApiKey="AIzaSyADkILxXgusP6VfyKExcU5Z7tVivY1e4RE">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={mapCenter}
              zoom={18}
            >
              <Marker position={mapCenter} />
            </GoogleMap>
          </LoadScript>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Content;
