import React from 'react';
import { makeStyles } from '@mui/styles';
import '@fortawesome/fontawesome-free/css/all.min.css';

const useStyles = makeStyles((theme) => ({
  topbar: {
    backgroundColor: '#0033A0', // Azul oscuro
    padding: '0',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
  },
  socialNetwork: {
    listStyle: 'none',
    margin: '5px 0 10px 0',
    padding: '0',
    display: 'flex',
    gap: '10px',
  },
  socialIcon: {
    display: 'inline-block',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    lineHeight: '32px',
    color: '#fff',
    transition: 'all .3s linear',
    '&:hover': {
      color: '#1bbde8',
    },
  },
}));

const TopHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.topbar}>
      <div className={classes.container}>
        <div className="col-sm-12">
          <ul className={classes.socialNetwork}>
            <li>
              <a href="#" className={classes.socialIcon}>
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#" className={classes.socialIcon}>
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#" className={classes.socialIcon}>
                <i className="fab fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="#" className={classes.socialIcon}>
                <i className="fab fa-pinterest"></i>
              </a>
            </li>
            <li>
              <a href="#" className={classes.socialIcon}>
                <i className="fab fa-google-plus"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
