import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDA2MJ4ct--G_4JSmFqED0mxM0MQiRjqnQ",
  authDomain: "macropoyectos-cartagena.firebaseapp.com",
  databaseURL: "https://macropoyectos-cartagena-default-rtdb.firebaseio.com",
  projectId: "macropoyectos-cartagena",
  storageBucket: "macropoyectos-cartagena.appspot.com",
  messagingSenderId: "213965874909",
  appId: "1:213965874909:web:e307ddc3aa6e9a41219d34",
  measurementId: "G-PCEWFL4RFP"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
