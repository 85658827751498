import React from 'react';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../store';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  navbar: {
    backgroundColor: '#f7f9fc', // Fondo gris claro
    padding: '15px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Sombra ligera
  },
  brand: {
    color: '#333333', // Gris oscuro para el texto
    fontSize: '24px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  nav: {
    listStyleType: 'none',
    display: 'flex',
    gap: '20px',
    margin: 0,
    padding: 0,
  },
  navItem: {
    color: '#555555', // Gris medio
    textDecoration: 'none',
    fontSize: '18px',
    '&:hover': {
      color: '#1bbde8', // Azul claro para el hover
    },
  },
}));

const Header = ({ setActivePage }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  return (
    <div className={classes.navbar}>
      <a href="#" className={classes.brand}>
        Consulta Atlántico
      </a>
      <ul className={classes.nav}>
        <li>
          <a href="#" className={classes.navItem} onClick={() => setActivePage('home')}>
            Home
          </a>
        </li>
        <li>
          <a href="#" className={classes.navItem} onClick={() => setActivePage('about')}>
            About
          </a>
        </li>
        <li>
          <a href="#" className={classes.navItem} onClick={handleLogout}>
            Logout
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Header;
