import { createStore, combineReducers } from 'redux';

// Acciones
const SET_USER = 'SET_USER';
const LOGOUT_USER = 'LOGOUT_USER';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});

// Reducer
const initialAuthState = {
  user: JSON.parse(localStorage.getItem('user')) || null
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case SET_USER:
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload
      };
    case LOGOUT_USER:
      localStorage.removeItem('user');
      return {
        ...state,
        user: null
      };
    default:
      return state;
  }
};

// Combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  // otros reducers
});

// Crear el store
const store = createStore(rootReducer);

export default store;
