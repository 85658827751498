import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  footer: {
    backgroundColor: '#f7f9fc', // Fondo gris claro
    padding: '20px 30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #e0e0e0', // Borde superior ligero
  },
  text: {
    color: '#555555', // Gris medio para el texto
    fontSize: '14px',
  },
  credits: {
    color: '#333333', // Gris oscuro para los créditos
    fontSize: '14px',
    textAlign: 'right',
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <div className={classes.text}>
        &copy; {new Date().getFullYear()} Consulta Atlántico. Todos los derechos reservados.
      </div>
      <div className={classes.credits}>
        Diseño por Leidy Vega Anaya y Fabián Muñoz Puello
      </div>
    </div>
  );
};

export default Footer;
