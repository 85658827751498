import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  slider: {
    maxWidth: 'auto',
    margin: '0 auto',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxHeight: '350px', // Establecer altura máxima
    objectFit: 'cover', // Asegurar que la imagen mantenga su proporción
  },
}));

const items = [
  {
    src: 'https://i.ibb.co/cX7STJv/Imagen-de-Whats-App-2024-07-28-a-las-18-07-51-1f47d0db.jpg',
    alt: 'Barranquilla at dusk',
  },
  {
    src: 'https://i.ibb.co/cX7STJv/Imagen-de-Whats-App-2024-07-28-a-las-18-07-51-1f47d0db.jpg',
    alt: 'Barranquilla Carnival',
  },
 
];

const Slider = () => {
  const classes = useStyles();

  return (
    <Carousel className={classes.slider}>
      {items.map((item, i) => (
        <Paper key={i}>
          <img src={item.src} alt={item.alt} className={classes.image} />
        </Paper>
      ))}
    </Carousel>
  );
};

export default Slider;
