import { createTheme } from '@mui/material/styles';
import { red, yellow, blue } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0033A0', // Azul oscuro
    },
    secondary: {
      main: '#F1C40F', // Amarillo
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: [
      'Arial',
      'sans-serif'
    ].join(','),
  },
});

export default theme;
